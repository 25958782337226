/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewHeader from "../../components/layout/ViewHeader";
import OrderClient from "./OrderClient";
import OrderItems from "./OrderItems";
import { useFormik } from "formik";
import validationSchema from "./ordersValidationSchema";
import { Box } from "@mui/system";
import { Button, CircularProgress } from "@mui/material";
import useOrders from "../../hooks/useOrders";
import { convertOrder } from "./utils";
import OrderStatusSteps from "./OrderStatusSteps";

type Props = {};

const initialValues = {
  stateOrdern: "Borrador",
  city: "",
  department: "",
  priceType: "MAYORISTA",
  total: 0,
  term: 0,
  financialDiscount: 0,
  client: "",
  clientMail: "",
  paymentMethod: "",
  invoiceType: "PEDIDO",
  date: new Date().toISOString().split("T")[0],
  origin: "01",
  addres: "",
  phone: "",
  destination: "01",
  operationNumber: 0,
  documentType: "PEDIDO",
  shoes: [],
};

const OrdersForm = (props: Props) => {
  const { id } = useParams();
  const { createOrder, getOrderById, editOrder } = useOrders();
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      handleSubmit();
    },
  });
  useEffect(() => {
    if (id) {
      setisLoading(true);
      getOrderById(id).then((res) => {
        const formattedDate = new Date(res.dateCreated)
          .toISOString()
          .split("T")[0];
        formik.setValues({
          ...res,
          client: res.client._id,
          date: formattedDate,
          city: res.city._id,
          department: res.department._id,
          shoes: res.shoes.map((shoe: any) => {
            return {
              ...shoe,
              color: shoe.shoeId.color_id.name,
              size: shoe.shoeId.size_id.name,
              brand: shoe.shoeId.brand_id.name,
            };
          }),
          // color: res.color_id.name,
        });
        setisLoading(false);
      });
    }
  }, [id]);

  const handleSubmit = async () => {
    const formatedOrder = convertOrder(formik.values);
    if (id) {
      editOrder(id, formatedOrder);
    } else {
      createOrder(formatedOrder).then((res) => formik.resetForm());
    }
  };

  return (
    <>
      <ViewHeader text={id ? "Orden" : "Nueva orden"} />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 5,
          }}
        >
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <>
          {id && <OrderStatusSteps activeStep={formik.values.stateOrdern} />}
          <form onSubmit={formik.handleSubmit}>
            <OrderClient formik={formik} isEdit={!!id} />
            <OrderItems formik={formik} />
            <Box
              sx={{
                my: 2,
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                size="large"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {id ? "Actualizar" : "Crear"} Orden
              </Button>
            </Box>
          </form>
        </>
      )}
    </>
  );
};

export default OrdersForm;
